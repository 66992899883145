<script>
export default {
  name: 'Start',
}
</script>

<template>
  <v-container
    class="text-center"
    fill-height
    style="height: calc(100vh - 58px)"
  >
    <div class="d-flex justify-center align-center flex-column mx-auto">
      <v-btn
        to="/admin/auth"
        color="primary"
        depressed
        block
        class="mb-5"
      >
        {{ $t("pages.start.btn-admin") }}
      </v-btn>
      <v-btn
        to="/auth"
        color="primary"
        depressed
        block
      >
        {{ $t("pages.start.btn") }}
      </v-btn>
    </div>
  </v-container>
</template>
